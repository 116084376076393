import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from 'store/utils';
import { ILeaderboardsResponse, TSpaceLeaderboardResponse, TSpaceSpecialLeaderboardResponse } from './leaderboard.types';

export const leaderboardApi = createApi({
  reducerPath: 'leaderboardApi',
  // refetchOnFocus: false,
  refetchOnReconnect: true,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getLeaderboardUsers: builder.query<ILeaderboardsResponse, void>({
      query: (query) => {
        return {
          url: '/leaderboards',
        };
      },
    }),
    getLeaderboardUsersBySpace: builder.query<TSpaceLeaderboardResponse, string>({
      query: (spaceName) => ({
        url: `/leaderboards/space/${spaceName}`,
      }),
    }),
    getSpecialLeaderboardUsersBySpace: builder.query<TSpaceSpecialLeaderboardResponse, { spaceName: string; token: string }>({
      query: ({ spaceName, token }) => ({
        url: `/leaderboards/partner/for-display/${spaceName}`,
        params: { token },
      }),
    }),
    updateCustomLeaderboard: builder.mutation<TSpaceSpecialLeaderboardResponse, { spaceName: string; token: string }>({
      query: ({ spaceName, token }) => ({
        url: `/leaderboards/partner/update-leaderboard-name/${spaceName}`,
        method: 'PUT',
        params: { token },
      }),
    }),
    exportUsers: builder.query<any, { spaceName: string; token: string }>({
      query: ({ spaceName, token }) => ({
        url: `/leaderboards/partner/users/export/to-file/${spaceName}`,
        method: 'GET',
        params: { token },
        responseHandler: (response: any) => {
          if (!response.ok) {
            return { error: response.statusText, status: response.status };
          }
          return response.blob();
        },
      }),
      transformResponse: async (response: Blob | void, meta: any, { token }) => {
        if (response instanceof Blob) {
          const { response: metaResponse } = meta;
          const contentType = metaResponse.headers.get('Content-Type');
          const file = new File([response], `${token}.xlsx`, { type: contentType });
          const url = window.URL.createObjectURL(file);
          window.open(url);
        }
      },
    }),
    importUsers: builder.mutation<TSpaceSpecialLeaderboardResponse, { spaceName: string; formData: FormData; token: string }>({
      query: ({ spaceName, formData, token }) => ({
        url: `/leaderboards/partner/import/file/${spaceName}`,
        method: 'PUT',
        params: { token },
        body: formData,
      }),
    }),
  }),
});

export const {
  useLazyGetLeaderboardUsersQuery,
  useLazyGetLeaderboardUsersBySpaceQuery,
  useLazyGetSpecialLeaderboardUsersBySpaceQuery,
  useUpdateCustomLeaderboardMutation,
  useImportUsersMutation,
  useLazyExportUsersQuery,
} = leaderboardApi;
