import { useWeb3Modal, useWeb3ModalTheme } from '@web3modal/wagmi/react';
import { useAccount, useSignMessage, useDisconnect } from 'wagmi';
import { useAttachUser, useDisclosure, useUnattachUser } from 'hooks';
import { useCallback, useState } from 'react';
import { useLazyGetSignatureMessageQuery, useLazyGetUserDataQuery, useLoginMFAMutation } from 'store';
import { EAuthMethods } from 'interface';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';
import { LOCAL_STORAGE_KEYS } from 'app/constants';

type TAuthData = {
  address: string;
  message: string;
  signature: string;
  referralCode?: string;
};

const useAuthentification = () => {
  const { signMessageAsync } = useSignMessage();
  const [generateMessage] = useLazyGetSignatureMessageQuery();
  const { address, isConnected } = useAccount();
  const [authData, setAuthData] = useState<null | TAuthData>(null);
  const { isOpen: isLoading, open: startLoading, close: stopLoading } = useDisclosure();
  const { close: closeModal } = useWeb3Modal();

  const handleAuthentification = useCallback(async () => {
    if (!address || !isConnected) {
      throw new Error('Connect your wallet first');
    }

    if (isLoading) return;
    startLoading();
    closeModal();
    try {
      const { message } = await generateMessage({ address, type: EAuthMethods.EVM }).unwrap();
      const signature = await signMessageAsync({ message });
      const referralCode = localStorage.getItem('referralCode') || undefined;
      setAuthData({ message, signature, referralCode, address });
      return { message, signature, referralCode, address };
    } catch (error: any) {
      console.error('Error during authentication:', error);
      throw error;
    } finally {
      stopLoading();
    }
  }, [address, isConnected, generateMessage, signMessageAsync, isLoading, startLoading, stopLoading, closeModal]);

  return { authData, setAuthData, handleAuthentification };
};

export const useEVMAuth = () => {
  const [login, { isLoading: isLogingIn }] = useLoginMFAMutation();
  const [getUser, { isFetching: isUserLoading }] = useLazyGetUserDataQuery();
  const { handleAttach: attach, isLoading: isAttaching } = useAttachUser({ method: EAuthMethods.EVM });
  const { handleUnattach, isLoading: isUnattaching } = useUnattachUser({ method: EAuthMethods.EVM });
  const { authData, setAuthData, handleAuthentification } = useAuthentification();
  const { setThemeVariables } = useWeb3ModalTheme();

  const { open: openModal, close: closeModal } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  const handleDisconnect = useCallback(() => {
    closeModal();
    disconnect();
    setAuthData(null);
  }, [disconnect, closeModal, setAuthData]);

  const handleLogin = useCallback(async () => {
    if (isLogingIn || isUserLoading || !isConnected) {
      return;
    }
    try {
      await handleAuthentification().then(async (authData) => {
        handleDisconnect();
        if (authData) {
          await login({ type: EAuthMethods.EVM, data: authData });
          await getUser();
        }
      });
    } catch (ex: any) {
      console.error('handleLogin error', ex);
      handleDisconnect();
      if (ex.data?.code === 'EVM_NONCE_IS_EXPIRED') {
        toast.error(<Answer label={'Oops!'} subtext="Nonce is expired. Please try again." type="incorrect" />);
      }
    }
  }, [isLogingIn, isUserLoading, isConnected]);

  const handleAttach = useCallback(async () => {
    if (isAttaching || !isConnected) return;
    try {
      await handleAuthentification().then(async (authData) => {
        handleDisconnect();
        if (authData) {
          await attach(authData);
        }
      });
    } catch (ex: any) {
      console.error('handleAttach error', ex);
      handleDisconnect();
      if (ex.data?.code === 'EVM_NONCE_IS_EXPIRED') {
        toast.error(<Answer label={'Oops!'} subtext="Nonce is expired. Please try again." type="incorrect" />);
      } else if (ex?.data?.message) {
        toast.error(<Answer label={'Oops!'} subtext={ex?.data?.message} type="incorrect" />);
      }
    }
  }, [isConnected, isAttaching]);

  const handleOpenModal = useCallback(() => {
    setThemeVariables({ '--w3m-z-index': 10000 });
    openModal();
  }, [openModal]);

  return {
    address,
    isConnected,
    isLogingIn,
    isUserLoading,
    isAttaching,
    isUnattaching,
    openModal: handleOpenModal,
    closeModal,
    handleLogin,
    handleAttach,
    handleUnattach,
    handleAuthentification,
    handleDisconnect,
    isAuthorized: !!authData,
  };
};
