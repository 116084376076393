import { EGroupTag, LANGS } from 'app/constants';
import { EAuthMethods } from 'interface';

export interface ISpaceDesc {
  language: LANGS;
  title: string;
}

interface ICustomizationPartBase {
  accentColor?: string;
  bgColor?: string;
}

export interface ISpaceCustomizationLeaderboards extends ICustomizationPartBase {
  images?: {
    bgImage?: string;
    bannerBgImage?: string;
  };
  isBannerShow?: boolean;
  customBannerLabel?: string;
  customBannerMonthlyLabel?: string;
}

export interface ISpaceCustomizationAuth extends ICustomizationPartBase {
  images?: {
    customLogo?: string;
    bgImage?: string;
  };
  authMethods?: { method: EAuthMethods; isSpecial?: boolean }[];
  title?: string;
}

export interface ISpaceCustomizationLessonPage extends ICustomizationPartBase {
  images?: {
    bgImage?: string;
  };
}

export enum EHiddenComponents {
  DASHBOARD = 'dashboard',
  QUESTS = 'quests',
  LEADERBOARD = 'leaderboard',
  COURSES = 'courses',
}

export enum EQuestPresets {
  ROUND = 'round',
  SQUARE = 'square',
}

export enum ECoursesPresets {
  DEFAULT = 'preset_1',
  LONG = 'preset_2',
  PIC_ONLY = 'preset_3',
  V2_FILLED = 'preset_4',
  V2_HOLLOW = 'preset_5',
  // QUEST_CIRCLE = 'preset_6',
  // QUEST_SQUARE = 'preset_7',
  // BANNER_1 = 'preset_8',
  // BANNER_2 = 'preset_9'
}

export interface ICourseColorsProps {
  color?: string;
  bgColor?: string;
  borderColor?: string;
}

export interface ISpaceCustomizationSpacePage extends ICustomizationPartBase {
  images?: {
    bgImage?: string;
  };
  hiddenComponents?: EHiddenComponents[];
  courseCardPreset?: ECoursesPresets;
  questsItemsPreset?: EQuestPresets;
}

export interface ISpaceCustomizationLoader extends ICustomizationPartBase {
  images?: {
    bgImage?: string;
  };
  variant?: string;
}

export interface ISpaceCustomizationBody {
  leaderboards?: ISpaceCustomizationLeaderboards;
  auth?: ISpaceCustomizationAuth;
  lessonPage?: ISpaceCustomizationLessonPage;
  spacePage?: ISpaceCustomizationSpacePage;
  loader?: ISpaceCustomizationLoader;
}

export interface ISpaceCustomization {
  dark: ISpaceCustomizationBody;
  light: ISpaceCustomizationBody;
}

export interface ISpaceCustomizationRequestBody extends ISpaceCustomizationBody {
  customLeaderboardCategories?: string[];
  spaceId: string;
}
export interface ISpaceCustomizationResponse extends ISpaceCustomization {
  customLeaderboardCategories?: string[];
}

export interface ISpace {
  _id: string;
  users: ISpaceUser[];
  name: string;
  link: string;
  description: ISpaceDesc[];
  image: string;
  imageThumb: string;
  tags: EGroupTag[];
  isVerified: boolean;
  customization?: ISpaceCustomization; // body as fallback
  defaultLeaderboard?: string;
}

export interface ISpaceShort {
  name: string;
  imageThumb: string;
  customization?: {
    imageLeaderboardBackground?: string;
    accentColor?: string;
  };
}

export interface IMySpacesResponseItem extends Omit<ISpace, 'users'> {
  users: IMySpaceRespUser[];
}

export interface ISpaceUser {
  roles: ESpaceRoles[];
  userId: {
    _id: string;
    nickname: string;
    avatar: string;
  };
  isSub?: boolean;
}

export interface IMySpaceRespUser extends Omit<ISpaceUser, 'userId'> {
  userId: string;
}

export enum ESpaceRoles {
  BASIC = 'BASIC',
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
}

export interface IAnalyticsCourse {
  name: string;
  estimatedTime: string;
  started: number;
  finished: number;
  completion: number;
  imageCover: string;
  isQuest: boolean;
}
