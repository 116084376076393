import { shallowEqual, useSelector } from 'react-redux';
import { getSpaceCustomization, ISpaceCustomization, ISpaceCustomizationBody } from 'store';
import { useGetCurrentTheme } from './useGetStoreGeneralData';
import { useMemo } from 'react';
import { EBgsPresets, PRESET_BGS, PRESET_PREVIEWS } from 'components/pages/spaces/SpaceDesign/constants';

type Props = {
  theme?: 'light' | 'dark';
  previewCustomization?: ISpaceCustomization;
  isShallowEqual?: boolean;
};

export const useGetSpaceCustomization = ({ theme, isShallowEqual, previewCustomization }: Props) => {
  const storeCustomization = useSelector(getSpaceCustomization, isShallowEqual ? shallowEqual : undefined);
  const currentCustomization = previewCustomization ?? storeCustomization;
  const appTheme = useGetCurrentTheme();
  const currentTheme = theme ?? appTheme;
  const customization: ISpaceCustomizationBody = useMemo(() => {
    if (!('light' in currentCustomization || 'dark' in currentCustomization)) {
      return currentCustomization as ISpaceCustomizationBody;
    }
    return (currentCustomization[currentTheme] ?? {}) as ISpaceCustomizationBody;
  }, [currentCustomization, currentTheme]);

  return customization;
};

interface IOptions {
  isUrl: boolean;
}

export const getPreparedCustomizationBg = (bgImage?: string, isPreview?: boolean, { isUrl }: IOptions = { isUrl: true }) => {
  if (!bgImage || bgImage === '[null]') return;

  const dict = isPreview ? PRESET_PREVIEWS : PRESET_BGS;

  if (bgImage in dict) {
    if (isUrl) return `url(${dict[bgImage as EBgsPresets]})`;
    return dict[bgImage as EBgsPresets];
  }
  if (!bgImage.includes('gradient')) {
    if (isUrl) return `url(${bgImage})`;
    return bgImage;
  }
  return bgImage;
};
