import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISpace, ISpaceCustomization, ISpaceCustomizationBody } from './types';
import { spacesApi } from './spaces.api';
import { RootStateType } from 'store/store';
import { LOCAL_STORAGE_KEYS } from 'app/constants';

const initialState: Partial<ISpaceCustomizationBody | ISpaceCustomization> = {};

const spaceCustomizationSlice = createSlice({
  name: 'spaceCustomizationSlice',
  initialState,
  reducers: {
    setSpaceCustomization: (_, { payload }: PayloadAction<Partial<ISpaceCustomization | ISpaceCustomizationBody>>) => {
      return payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(spacesApi.endpoints.getSpaceByName.matchFulfilled, (_, { payload }: PayloadAction<ISpace>) => {
      if (payload.customization) {
        const loaderLight = payload.customization?.light.loader;
        const loaderDark = payload.customization?.dark.loader;
        if (loaderLight || loaderDark) {
          const loaderPayload: ISpaceCustomization = {
            dark: {
              loader: loaderDark,
            },
            light: {
              loader: loaderLight,
            },
          };
          localStorage.setItem(LOCAL_STORAGE_KEYS.LOADER_CUSTOMIZATION, JSON.stringify({ ...loaderPayload }));
        } else {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.LOADER_CUSTOMIZATION);
        }
        return payload.customization;
      }
      return initialState;
    });
  },
});

const _getSpaceCustomization = (state: RootStateType) => state.spaceCustomization;
export const getSpaceCustomization = createSelector(_getSpaceCustomization, (customization) => customization);

export const { setSpaceCustomization } = spaceCustomizationSlice.actions;
export default spaceCustomizationSlice.reducer;
