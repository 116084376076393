export const useScrollOff = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const scrollOff = searchParams.get('scrollOff');
  return scrollOff === 'true';
};

export const useStudyScrollbarHidden = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const studyScrollbarHidden = searchParams.get('studyScrollbarHidden');
  return studyScrollbarHidden === 'true';
};

export const useStudyHeadOff = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const studyHeadOff = searchParams.get('studyHeadOff');
  return studyHeadOff === 'true';
};

export const useHideSidebards = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const hideSidebards = searchParams.get('hideSidebards');
  return hideSidebards === 'true';
};
