import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EMenuScreens } from 'app/constants';
import { TPanels } from 'components/library/navbar/UserMenu/types';
import { RootStateType } from 'store';
import { IIsOpenSidePanel } from 'store/profile/profile.types';
import { profileApi } from '../profile/profile.api';

interface IInitialState {
  isFriendFinder: boolean;
  isMainMenu: boolean;
  isSidebar: boolean;
  mainMenuScreen: EMenuScreens;
  activeUserMenuPanel: TPanels;
  isShowSuccessAuthModal: boolean;
  isOpenSidePanel: IIsOpenSidePanel;
}

const initialState: IInitialState = {
  isFriendFinder: false,
  isMainMenu: false,
  isSidebar: false,
  mainMenuScreen: EMenuScreens.SETTINGS,
  activeUserMenuPanel: 'none',
  isShowSuccessAuthModal: false,
  isOpenSidePanel: {
    multiPanel: false,
    shopPanel: false,
  },
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setIsShowSuccessAuthModal: (state, action: PayloadAction<boolean>) => {
      state.isShowSuccessAuthModal = action.payload;
    },
    setIsOpenSidePanel: (state, action: PayloadAction<IIsOpenSidePanel>) => {
      state.isOpenSidePanel = { ...state.isOpenSidePanel, ...action.payload };
    },
    setIsFriendFinder: (state, action: PayloadAction<boolean>) => {
      state.isFriendFinder = action.payload;
    },
    setIsMainMenu: (state, action: PayloadAction<{ isMainMenu: boolean; screen?: EMenuScreens }>) => {
      state.isMainMenu = action.payload.isMainMenu;
      state.mainMenuScreen = action.payload.screen ?? EMenuScreens.PROFILE;
    },
    setIsSidebar: (state, action: PayloadAction<boolean>) => {
      state.isSidebar = action.payload;
    },
    setActiveUserMenuPanel: (state, action: PayloadAction<TPanels>) => {
      if (state.activeUserMenuPanel === action.payload) {
        state.activeUserMenuPanel = 'none';
      } else {
        state.activeUserMenuPanel = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(profileApi.endpoints.activateUser.matchFulfilled, (state, _) => {
      state.isShowSuccessAuthModal = true;
    });
  },
});

export const { setIsOpenSidePanel, setIsShowSuccessAuthModal, setIsFriendFinder, setIsMainMenu, setIsSidebar, setActiveUserMenuPanel } =
  modalsSlice.actions;

const getIsFriendFinderForSelector = (state: RootStateType) => state.modals.isFriendFinder;
const getIsMainMenuForSelector = (state: RootStateType) => state.modals.isMainMenu;
const getMainMenuScreenForSelector = (state: RootStateType) => state.modals.mainMenuScreen;
const getIsSidebarForSelector = (state: RootStateType) => state.modals.isSidebar;
const getActiveUserMenuPanelForSelector = (state: RootStateType) => state.modals.activeUserMenuPanel;
const getIsShowSuccessAuthModalForSelector = (state: RootStateType) => state.modals.isShowSuccessAuthModal;
const getIsOpenSidePanelForSelector = (state: RootStateType) => state.modals.isOpenSidePanel;

export const getIsMainMenu = createSelector(getIsMainMenuForSelector, (isMainMenu) => isMainMenu);
export const getIsFriendFinder = createSelector(getIsFriendFinderForSelector, (isFriendFinder) => isFriendFinder);
export const getMainMenuScreen = createSelector(getMainMenuScreenForSelector, (mainMenuScreen) => mainMenuScreen);
export const getIsSidebar = createSelector(getIsSidebarForSelector, (isSidebar) => isSidebar);
export const getActiveUserMenuPanel = createSelector(getActiveUserMenuPanelForSelector, (activeUserMenuPanel) => activeUserMenuPanel);
export const getIsShowSuccessAuthModal = createSelector(getIsShowSuccessAuthModalForSelector, (isShowSuccessAuthModal) => isShowSuccessAuthModal);
export const getIsOpenSidePanel = createSelector(getIsOpenSidePanelForSelector, (isOpenSidePanel) => isOpenSidePanel);
export default modalsSlice.reducer;
