import { ROUTES } from 'app/constants';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useNavigateToAuth = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navToAuth = useCallback(
    (path = pathname, state = {}) => {
      navigate(ROUTES.AUTH, { state: { prev: path, ...state } });
    },
    [pathname]
  );

  return navToAuth;
};

export default useNavigateToAuth;
