import { useEffect, useState } from 'react';
import { useGetIsAuth } from './useGetStoreGeneralData';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/constants';
import { useSelector } from 'react-redux';
import { getPartnerId } from 'store';

export const useNavigateUnauthUser = () => {
  const isAuth = useGetIsAuth();
  const [prevAuth, setPrevAuth] = useState(isAuth);
  const [wasNavigated, setWasNavigated] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const partnerId = useSelector(getPartnerId);
  // TODO понять что это и выбросить, если не нужно
  useEffect(() => {
    if (partnerId) return;
    if (isAuth && wasNavigated) {
      setWasNavigated(false);
    }
    if (!isAuth && !!prevAuth && !wasNavigated) {
      navigate(ROUTES.AUTH, { state: { prev: pathname } });
      setWasNavigated(true);
      setPrevAuth(false);
    }
    if (!isAuth && pathname === '/' && !prevAuth) {
      navigate(ROUTES.MAIN);
    }
  }, [isAuth, wasNavigated, pathname, prevAuth]);
};
