import preset1 from './assets/preset-1.png';
import preset2 from './assets/preset-2.png';
import preset3 from './assets/preset-3.png';
import preset4 from './assets/preset-4.png';
import preset5 from './assets/preset-5.png';
import preset6 from './assets/preset-6.png';

import preset1Light from './assets/preset-1-light.png';
import preset2Light from './assets/preset-2-light.png';
import preset3Light from './assets/preset-3-light.png';
import preset4Light from './assets/preset-4-light.png';
import preset5Light from './assets/preset-5-light.png';
import preset6Light from './assets/preset-6-light.png';
import { THEMES } from 'app/constants';

export enum EBgsPresets {
  _1 = '[1]',
  _2 = '[2]',
  _3 = '[3]',
  _4 = '[4]',
  _5 = '[5]',
  _6 = '[6]',
  _7 = '[7]',
  _8 = '[8]',
  _9 = '[9]',
  _10 = '[10]',
  _11 = '[11]',
  _12 = '[12]',
}

export const PRESET_BGS = {
  [EBgsPresets._1]: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/bg-preset-1.webp',
  [EBgsPresets._2]: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/bg-preset-2.webp',
  [EBgsPresets._3]: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/bg-preset-3.webp',
  [EBgsPresets._4]: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/bg-preset-4.webp',
  [EBgsPresets._5]: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/bg-preset-5.webp',
  [EBgsPresets._6]: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/bg-preset-6.webp',
  [EBgsPresets._7]: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/bg-preset-1--light.webp',
  [EBgsPresets._8]: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/bg-preset-2--light.webp',
  [EBgsPresets._9]: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/bg-preset-3--light.webp',
  [EBgsPresets._10]: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/bg-preset-4--light.webp',
  [EBgsPresets._11]: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/bg-preset-5--light.webp',
  [EBgsPresets._12]: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/bg-preset-6--light.webp',
};

export const PRESET_PREVIEWS = {
  [EBgsPresets._1]: preset1,
  [EBgsPresets._2]: preset2,
  [EBgsPresets._3]: preset3,
  [EBgsPresets._4]: preset4,
  [EBgsPresets._5]: preset5,
  [EBgsPresets._6]: preset6,
  [EBgsPresets._7]: preset1Light,
  [EBgsPresets._8]: preset2Light,
  [EBgsPresets._9]: preset3Light,
  [EBgsPresets._10]: preset4Light,
  [EBgsPresets._11]: preset5Light,
  [EBgsPresets._12]: preset6Light,
};

export const DEFAULT_COLORS = {
  bgColor: {
    [THEMES.DARK]: '#242944',
    [THEMES.LIGHT]: '#FDFDFD',
  },
  accentColor: {
    [THEMES.DARK]: '#FDFDFD',
    [THEMES.LIGHT]: '#111111',
  },
};
